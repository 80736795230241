import { PortableText } from "@portabletext/react";
import type {
	PortableTextProps,
	PortableTextReactComponents,
} from "@portabletext/react";
import { BulletList, OrderList, ListItem } from "~/components/list";
import { merge } from "lodash";
import {
	H1,
	H2,
	H3,
	H4,
	H5,
	H6,
	Paragraph,
	Blockquote,
} from "~/components/ui/typography";
import type { TitleProps } from "~/components/ui/typography";
import clsx from "clsx";
import { Link } from "~/components/ui/link";
import snarkdown from "snarkdown";
import { ImageSectionComponent } from "./image";
import {
	getImageBuilder,
	getImageProps,
	getImageAltProp,
} from "~/utils/images";
import { Iframe, IframeHeader } from "./iframe";
import { asText } from "~/utils/sanity-helpers";
import { Table } from "./ui/table";
import { getLocalePath } from "~/utils/language";
import { Image } from "~/components/ui/image";
import Quote from "~/images/shape-quote.svg";

type BlockComponent = PortableTextReactComponents["block"];
type TableRow = {
	cells: string[];
	_key: string;
	_type: "tableRow";
};
interface Props {
	paragraphColor?: TitleProps["color"];
	headingClassName?: string;
	paragraphClassName?: string;
	tableClassName?: string;
	value: PortableTextProps["value"];
	blockOverrides?: BlockComponent;
}

const InnerHtmlElement = ({
	children,
	...rest
}: {
	children: string;
}): JSX.Element => (
	<div
		className="prose-snackdown html-with-link"
		{...rest}
		dangerouslySetInnerHTML={{ __html: children }}
	/>
);

function fixLineBreaks(text: string) {
	return text.replace("\\n", "<br />");
}

export const BlockContent = ({
	paragraphColor = "secondary",
	headingClassName,
	paragraphClassName,
	tableClassName,
	value,
	blockOverrides = {},
}: Props) => {
	if (!value) {
		console.error("Missing blocks");
		return null;
	}

	return (
		<PortableText
			value={value}
			components={{
				block: merge<BlockComponent, BlockComponent>(
					{
						h1: ({ children }) => (
							<H1 className={clsx("mb-6 mt-9", headingClassName)}>
								{children}
							</H1>
						),
						h2: ({ children }) => (
							<H2 className={clsx("mb-6 mt-9", headingClassName)}>
								{children}
							</H2>
						),
						h3: ({ children }) => (
							<H3 className={clsx("mb-5 mt-7", headingClassName)}>
								{children}
							</H3>
						),
						h4: ({ children }) => (
							<H4 className={clsx("mb-3 mt-6", headingClassName)}>
								{children}
							</H4>
						),
						h5: ({ children }) => (
							<H5 className={clsx("mb-3 mt-6", headingClassName)}>
								{children}
							</H5>
						),
						h6: ({ children }) => (
							<H6 className={clsx("mb-3 mt-6", headingClassName)}>
								{children}
							</H6>
						),
						normal: ({ children }) => (
							<Paragraph
								className={clsx("mb-5 last:mb-0", paragraphClassName)}
								color={paragraphColor}
							>
								{children}
							</Paragraph>
						),
						blockquote: ({ children }) => (
							<Blockquote
								className={clsx(
									"my-8 rounded-lg bg-theme-secondary p-9 text-center text-lg [&>em]:not-italic",
									paragraphClassName
								)}
							>
								<Image
									className="mx-auto pb-6"
									width="44px"
									height="33px"
									src={Quote}
									alt=""
								/>
								{children}
							</Blockquote>
						),
						small: ({ children }) => (
							<Paragraph size="body-small" color={paragraphColor}>
								{children}
							</Paragraph>
						),
					},
					blockOverrides
				),
				marks: {
					em: ({ children }) => <em>{children}</em>,
					link: ({ value, children }) => {
						return (
							<Link
								className="text-link relative"
								to={value?.href}
								trackingText={(children as React.ReactNode[])[0] as string}
								trackingPosition="text"
							>
								{children}
							</Link>
						);
					},
					internalLink: ({ value, children }) => {
						const slug = value?.slug?.current;
						const locale = value?.locale || "en";
						const url = getLocalePath(value?.slug?.current, locale);
						return slug ? (
							<Link
								className="text-link"
								to={url}
								trackingText={(children as React.ReactNode[])[0] as string}
								trackingPosition="text"
							>
								{children}
							</Link>
						) : (
							<span>{children}</span>
						);
					},
					code: ({ children }) => {
						return (
							<code className="border-stroke my-1 inline-block break-all border bg-grey-0 px-2 font-code">
								{children}
							</code>
						);
					},
					"color-primary": ({ children }) => {
						return <span className="text-primary-80">{children}</span>;
					},
					"color-secondary": ({ children }) => {
						return <span className="text-secondary-70">{children}</span>;
					},
				},
				list: {
					bullet: ({ children }) => <BulletList>{children}</BulletList>,
					number: ({ children }) => (
						<OrderList color={paragraphColor}>{children}</OrderList>
					),
				},
				listItem: {
					bullet: ({ children }) => (
						<ListItem color={paragraphColor} className={paragraphClassName}>
							{children}
						</ListItem>
					),
					number: ({ children }) => (
						<ListItem color={paragraphColor} className={paragraphClassName}>
							{children}
						</ListItem>
					),
				},
				types: {
					table: ({ value }) => {
						const { rows }: { rows: TableRow[] | undefined } = value;
						if (!rows || rows.length === 0) {
							return null;
						}
						const [heading, ...body] = rows;

						return (
							<div className={clsx("mb-5")}>
								<Table
									className={clsx(
										"my-layout4 table-auto text-left",
										tableClassName
									)}
								>
									{/* minus 1px helps with the tiny gap between the table head and nav */}
									<Table.Head sticky top={`calc(var(--nav-height) -1px)`}>
										{heading.cells.map(
											// eslint-disable-next-line @typescript-eslint/no-explicit-any
											(cell: any, j: number) => (
												<Table.Cell key={`${j}`}>
													<InnerHtmlElement>{snarkdown(cell)}</InnerHtmlElement>
												</Table.Cell>
											)
										)}
									</Table.Head>
									<Table.Body>
										{body.map((row) => (
											<Table.Row key={row._key}>
												{row.cells.map((cell, j) => (
													<Table.Cell key={`${row._key}-${j}`}>
														<InnerHtmlElement>
															{snarkdown(fixLineBreaks(cell))}
														</InnerHtmlElement>
													</Table.Cell>
												))}
											</Table.Row>
										))}
									</Table.Body>
								</Table>
							</div>
						);
					},
					image: ({ value }) => {
						const content = {
							...getImageProps(
								getImageBuilder(value, {
									alt: getImageAltProp(value),
								}),
								{
									widths: [400, 600, 800, 1000],
									sizes: ["(min-width:1024px) 90vw", "100vw"],
								}
							),
							caption: "",
						};
						return <ImageSectionComponent content={content} />;
					},
					iframe: ({ value }) => {
						return (
							<>
								<IframeHeader
									align={value.align}
									title={asText(value.title)}
									compactTitle
									subtitle={value.subtitle}
								/>
								<Iframe
									title={asText(value.iframeTitle)}
									src={asText(value.iframeSource)}
									minHeight={value.iframeHeight}
									isPardotForm={value.isPardotForm}
									pardotFormType={value.pardotFormType}
								/>
							</>
						);
					},
				},
			}}
		/>
	);
};
