import React from "react";
import clsx from "clsx";
import { Link } from "~/components/ui/link";
import { Paragraph, Title } from "~/components/ui/typography";
import IframeResizer from "@iframe-resizer/react";
import { useLocation } from "react-router-dom";
import { BlockContent } from "~/components/block-content";
import { AnimatedLoading } from "./animated-loading";
import type { PardotFormType, SimplePortableText } from "~/types/sanity-schema";
import { ButtonLink } from "~/components/ui/button";
import type { ImageProps } from "~/components/ui/image";
import { Image } from "~/components/ui/image";
import { spTrackWebInteraction } from "~/utils/tracking";

interface IframeProps {
	headerPosition?: "default" | "left";
	title?: string;
	src: string;
	minHeight?: number;
	bgColor?: string;
	additionalParams?: Record<string, string>;
	disclaimer?: SimplePortableText;
	isPardotForm?: boolean;
	pardotFormType?: PardotFormType;
	pardotSubmitCallback?: () => void;
}

export const Iframe = React.memo(function Iframe({
	headerPosition = "default",
	title,
	src,
	minHeight = 800,
	bgColor,
	additionalParams,
	disclaimer,
	isPardotForm,
	pardotFormType,
	pardotSubmitCallback,
}: IframeProps) {
	const location = useLocation();

	const { pathname, search: param } = location;

	const refer = pathname[0] === "/" ? pathname.substring(1) : pathname;

	const isValidParam =
		/utm_source|utm_medium|utm_campaign|utm_term|utm_content|department/.test(
			param
		);

	const queryString = additionalParams
		? new URLSearchParams({
				...additionalParams,
				referrer: refer,
			}).toString()
		: "";

	let iframeSrc = src;
	// Ensure src does not end with ? or &
	if (["?", "&"].includes(iframeSrc.slice(-1))) {
		iframeSrc = iframeSrc.slice(0, -1);
	}

	if (isValidParam) {
		iframeSrc += `${param}`;
		if (queryString) {
			iframeSrc += `&${queryString}`;
		}
	} else if (queryString) {
		iframeSrc += `?${queryString}`;
	}

	const [isLoading, setLoading] = React.useState<boolean>(true);

	// Hide loading after 500ms
	React.useEffect(() => {
		const timeoutId = window.setTimeout(() => {
			setLoading(false);
		}, 500);

		return () => {
			window.clearInterval(timeoutId);
		};
	}, []);

	const handleIframeLoaded = () => {
		setLoading(false);
	};

	React.useEffect(() => {
		if (!isPardotForm) {
			return;
		}

		const onMessage = (event: MessageEvent) => {
			const { data: eventData } = event;
			try {
				const jsonData = JSON.parse(eventData);
				const formType = pardotFormType ? pardotFormType : "lead";

				if (jsonData.event === "iFrameFormSubmit") {
					spTrackWebInteraction({
						object: "form",
						action: "submit",
						value: formType,
					});

					if (pardotSubmitCallback) {
						pardotSubmitCallback();
					}
					// send submit event to 6sense
					if (window._6si) {
						const formData = {
							form_type: formType,
							form_url: jsonData.formUrl,
						};

						window._6si.push(["sendFormData", formData]);
					}
				}
			} catch (error) {
				return;
			}
		};

		window.addEventListener("message", onMessage);

		return () => window.removeEventListener("message", onMessage);
	}, [isPardotForm, pardotFormType, pardotSubmitCallback]);

	return (
		<div
			className={clsx("relative isolate", {
				"bg-primary rounded-lg p-7 shadow-md lg:ml-6":
					headerPosition === "left",
			})}
			style={{
				backgroundColor: bgColor,
				minHeight: `calc(${minHeight}px + 8px)`,
			}}
		>
			{isLoading ? (
				<AnimatedLoading className="relative p-6" />
			) : (
				<>
					<IframeResizer
						license={ENV.IFRAME_RESIZER_LICENSE ?? "GPLv3"}
						title={title}
						src={iframeSrc}
						width="100%"
						referrerPolicy="no-referrer"
						sandbox="allow-forms allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-top-navigation"
						className="relative z-20"
						style={{
							backgroundColor: "inherit",
							height: `${minHeight}px`,
						}}
						bodyMargin="0 0 8px 0"
						onLoad={handleIframeLoaded}
						checkOrigin={false}
					/>
					<div className="absolute left-0 top-0 z-10 hidden first:block">
						<Paragraph>
							This form was disabled by a content blocking extension. We get it
							– the internet&#39;s a busy place these days. Don&#39;t worry -
							you can{" "}
							<Link
								className="text-link"
								to={isValidParam ? `${src}${param}` : `${src}`}
								trackingText="Access pardot form"
								trackingPosition="text"
							>
								access the form here
							</Link>
							.
						</Paragraph>
					</div>
				</>
			)}

			{disclaimer ? (
				<>
					<hr className="my-layout2 text-grey-10" />
					<BlockContent value={disclaimer} />
				</>
			) : null}
		</div>
	);
});

interface IframeHeaderProps {
	align?: string;
	tagline?: string;
	title?: string;
	titleAsH1?: boolean;
	subtitle?: SimplePortableText;
	compactTitle?: boolean;
	qualifiedExperienceLink?: {
		title?: string;
		experienceId?: string;
	};
	image?: ImageProps;
}

export function IframeHeader({
	align = "left",
	tagline,
	title,
	titleAsH1,
	subtitle,
	compactTitle = false,
	qualifiedExperienceLink,
	image,
}: IframeHeaderProps) {
	return (
		<>
			<div
				className={clsx("mb-layout4 max-w-content", {
					"mx-auto text-center": align === "center",
				})}
			>
				{tagline ? (
					<Paragraph size="overline" className="mb-5" color="tagline">
						{tagline}
					</Paragraph>
				) : null}
				{title ? (
					<Title
						className="mb-5"
						size={
							compactTitle ? "heading-5" : titleAsH1 ? "heading-1" : "heading-2"
						}
						fontWeight={compactTitle ? "font-medium" : "font-semibold"}
					>
						{title}
					</Title>
				) : null}
				{subtitle ? <BlockContent value={subtitle} /> : null}
				{qualifiedExperienceLink?.title &&
				qualifiedExperienceLink?.experienceId ? (
					<ButtonLink
						to={`#${qualifiedExperienceLink.experienceId}`}
						data-qualified-action="showExperience"
						data-qualified-arg={qualifiedExperienceLink.experienceId}
						trackingPosition="qualified"
						variant="secondary"
						color="secondary"
					>
						{qualifiedExperienceLink.title}
					</ButtonLink>
				) : null}
				{image?.src ? (
					<Image
						className="mt-9 hidden max-w-[500px] lg:block"
						loading="eager"
						fetchpriority="high"
						{...image}
					/>
				) : null}
			</div>
		</>
	);
}

interface IframeGroupProps {
	headerPosition?: "default" | "left";
	align?: string;
	tagline?: string;
	title?: string;
	titleAsH1?: boolean;
	subtitle?: SimplePortableText;
	qualifiedExperienceLink?: {
		title?: string;
		experienceId?: string;
	};
	image?: ImageProps;
	iframeTitle?: string;
	iframeSource: string;
	iframeHeight?: number;
	iframeFullWidth?: boolean;
	iframeBgColor?: string;
	additionalParams?: Record<string, string>;
	disclaimer?: SimplePortableText;
	isPardotForm?: boolean;
	pardotFormType?: PardotFormType;
	pardotSubmitCallback?: () => void;
}

export function IframeGroup({
	headerPosition = "default",
	align = "left",
	tagline,
	title,
	titleAsH1,
	subtitle,
	qualifiedExperienceLink,
	image,
	iframeTitle,
	iframeSource,
	iframeHeight = 800,
	iframeFullWidth,
	iframeBgColor,
	disclaimer,
	isPardotForm,
	pardotFormType,
	pardotSubmitCallback,
}: IframeGroupProps) {
	return (
		<div
			className={clsx({
				"gap-layout4 lg:flex lg:flex-row [&>*]:lg:w-1/2":
					headerPosition === "left",
				"mx-auto max-w-content":
					headerPosition === "default" && !iframeFullWidth,
				"relative left-1/2 w-screen -translate-x-1/2 transform":
					headerPosition === "default" && iframeFullWidth,
			})}
		>
			<IframeHeader
				align={align}
				tagline={tagline}
				title={title}
				titleAsH1={titleAsH1}
				subtitle={subtitle}
				qualifiedExperienceLink={qualifiedExperienceLink}
				image={image}
			/>
			<Iframe
				headerPosition={headerPosition}
				title={iframeTitle}
				src={iframeSource}
				minHeight={iframeHeight}
				bgColor={iframeBgColor}
				disclaimer={disclaimer}
				isPardotForm={isPardotForm}
				pardotFormType={pardotFormType}
				pardotSubmitCallback={pardotSubmitCallback}
			/>
		</div>
	);
}
